import { Box, Stack, styled, SxProps, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { firebaseDateToText } from "../../../common/utils";

interface MapMarkerProps {
  lat: number;
  lng: number;
  user: any;
  type: 'clockIn' | 'clockOut';
  hovered: boolean;
  onClickDriver: () => void;
}

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#444',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444',
  },
}));

const MapMarker = ({ lat, lng, user, type, hovered, onClickDriver }: MapMarkerProps) => {
  const userDetails = Object.keys(user).reduce((acc: any, key) => {
    switch (key) {
      case 'clockOut':
        if (type === 'clockOut') {
          acc.push(['Clock Out', firebaseDateToText(user.clockOut.time)]);
        }
        break;
      case 'clockIn':
        if (type === 'clockIn') {
          acc.push(['Clock In', firebaseDateToText(user.clockIn.time)]);
        }
        break;
      default:
        break;
    }
    return acc
  }, [])

  const iconAttr = {
    size: 26
  };
  const markerIcon = (() => {
    switch (type) {
      case 'clockOut':
        return <FaSignOutAlt size={iconAttr.size} color="#001529" />;
      case 'clockIn':
      default:
        return <FaSignInAlt size={iconAttr.size} color="#eec022" />;
    }
  })();

  const textSx: SxProps = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre' };
  const tooltipContent = (
    <Box>
      {userDetails.map((deets, index) => (
        <Stack key={index} direction="row" alignItems="center" gap={1}>
          <Typography variant="body2" sx={textSx}>
            {deets[0]}:
          </Typography>
          <Typography variant="body2" sx={{ ...textSx, fontWeight: 800 }}>
            {deets[1]}
          </Typography>
        </Stack>
      ))}
    </Box>
  );

  return (
    <CustomToolTip open={hovered} title={tooltipContent} placement="bottom" arrow>
      <Box
        sx={{ cursor: 'pointer', width: iconAttr.size }}
        onClick={onClickDriver}>
        {markerIcon}
      </Box>
    </CustomToolTip>
  )
}

export default MapMarker;
import { useEffect, useState } from 'react';
import { Container } from '../../../components/common/Containers'

import { Span } from '../../../components/common/Span';
import { FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemText, Stack, styled, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import MapMarker from '../Map/MapMarker';
import { firebaseDateToText } from '../../../common/utils';
import Loader from '../../../components/Loader';

const CustomSwitch = styled(Switch)(() => ({
  padding: 6,
  width: '80px',
  height: '34px',
  '& .MuiSwitch-switchBase': {
    padding: '7px',
    '&.Mui-checked': {
      transform: 'translateX(45px)',
      '& + .MuiSwitch-track::before': {
        opacity: 1
      },
      '& + .MuiSwitch-track::after': {
        opacity: 0
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    color: '#fff',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&::before': {
      content: '"Show"',
      left: 14,
      opacity: 0,
    },
    '&::after': {
      content: '"Hide"',
      right: 18,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

let UNSUBSCRIBE: any;

const AllTimesheetTrack = () => {
  let { timesheetId } = useParams<{timesheetId: string}>();
  const db = getFirestore();

  const theme = useTheme();
  const mobile = useMediaQuery('(max-width:600px)')
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  
  let [data, setData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);
  const [showClockIn, setShowClockIn] = useState(true);
  const [showClockOut, setShowClockOut] = useState(true);
  const [activeMarker, setActiveMarker] = useState<any>(null);
  
  const defaultCenter = { lat: 14.59, lng: 120.98 };
  const defaultZoom = 11;
  const [center, setCenter] = useState<{ lat: number; lng: number }>(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  const getData = async () => {
    if (UNSUBSCRIBE) UNSUBSCRIBE();

    UNSUBSCRIBE = onSnapshot(doc(db, "timesheet", timesheetId), (doc) => {
      if (doc.exists()) {
        setData(doc.data());
        setIsLoading(false);
      } else {
        console.log("No such document!");
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.clockIn) {
      setCenter({ lat: data.clockIn.location.latitude, lng: data.clockIn.location.longitude});
    }
  }, [data?.clockIn?.location])
  
  const userDetails = Object.keys(data).reduce((acc: any, key) => {
    switch (key) {
      case 'userInfo':
        acc[0] = ['Name', data.userInfo.name];
        acc[1] = ['Employee Number', data.userInfo.employeeNumber];
        acc[2] = ['Department', data.userInfo.department];
        acc[3] = ['Position', data.userInfo.position];
        break;
      case 'clockIn':
        acc[4] = ['Clock In', firebaseDateToText(data.clockIn.time)];
        break;
      case 'clockOut':
        acc[5] = ['Clock Out', firebaseDateToText(data.clockOut.time)];
        break;
      default:
        break;
    }
    return acc
  }, [])

  const onClickMarker = (user, type) => {
    if (activeMarker === user[type].location) {
      setActiveMarker(null);
      // setCenter(defaultCenter);
      setZoom(defaultZoom);
    } else {
      setActiveMarker(user[type].location);
      setCenter({ lat: user[type].location.latitude, lng: user[type].location.longitude });
      setZoom(15);
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <Container border='grey' padding='none'>
        <div className='p-4'>
          <div className={`flex ${mobile ? 'justify-center' : 'justify-start'} items-center`}>
            <Span margin='mt-4' weight='bold' size='2xl'>Track Timesheet</Span>
          </div>
        </div>

        <div className='overflow-auto' style={{ height: mobile ? '55vh' : 'auto' }}>
          <Grid container spacing={1} p={1} {...(!downLG && { height: '700px' })}>
            <Grid item xs={12} lg={9} height={downLG ? '400px' : 1}>
              <GoogleMapReact
                center={center}
                defaultZoom={defaultZoom}
                draggable={true}
                zoom={zoom}
                options={{
                  streetViewControl: true,
                  styles: [
                    {
                      featureType: "poi",
                      elementType: "labels",
                      stylers: [{ visibility: "on" }],
                    },
                  ],
                }}
                bootstrapURLKeys={{ key: process.env.REACT_APP_API_MAP_KEY || "" }}
                >
                  {showClockOut && data?.clockOut && (
                    <MapMarker
                      lat={data.clockOut.location.latitude}
                      lng={data.clockOut.location.longitude}
                      user={data}
                      type='clockOut'
                      hovered={data.clockOut.location === activeMarker}
                      onClickDriver={() => onClickMarker(data, 'clockOut')}/>
                  )}
                  {showClockIn && data?.clockIn && (
                    <MapMarker
                      lat={data.clockIn.location.latitude}
                      lng={data.clockIn.location.longitude}
                      user={data}
                      type='clockIn'
                      hovered={data.clockIn.location === activeMarker}
                      onClickDriver={() => onClickMarker(data, 'clockIn')}/>
                  )}
              </GoogleMapReact>
            </Grid>
            <Grid item xs={12} lg={3} height={1}>
              <Stack spacing={2} height={1}>
                <Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Clock In</Typography>
                    <FormControlLabel control={<CustomSwitch checked={showClockIn} onChange={(e) => setShowClockIn(e.currentTarget.checked)} />} label="" sx={{ m: 0 }} />
                  </Stack>
                  {data?.clockOut && (
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6">Clock Out</Typography>
                      <FormControlLabel control={<CustomSwitch checked={showClockOut} onChange={(e) => setShowClockOut(e.currentTarget.checked)} />} label="" sx={{ m: 0 }} />
                    </Stack>
                  )}
                </Stack>
                <List dense>
                  {userDetails
                    .map((details, index) => {
                      const clockInOut = ['Clock In', 'Clock Out'].includes(details[0]);
                      const listText = <ListItemText primary={details[0]} sx={{ margin: 0.5 }} />;
                      const listContent = clockInOut ? (
                        <ListItemButton onClick={() => onClickMarker(data, details[0] === 'Clock In' ? 'clockIn' : 'clockOut')}>
                          {listText}
                        </ListItemButton>
                      ) : listText;

                      return (
                        <ListItem
                          key={index}
                          secondaryAction={<ListItemText primary={details[1]} />}
                          disablePadding={clockInOut}
                          sx={{ ...(index % 2 === 1 && {bgcolor: 'lightgray'})}}>
                            {listContent}
                        </ListItem>
                      )
                  })}
                </List>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Container >
    </div >
  )
}

export default AllTimesheetTrack;
